import Vue from 'vue'
import App from './App.vue'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
import './plugins/vue-gtag'
import VueApexCharts from 'vue-apexcharts'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import VueResource from 'vue-resource'
import { VueReCaptcha } from 'vue-recaptcha-v3'


// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/

Vue.use(VueApexCharts)
Vue.use(VueResource);
Vue.use(VueReCaptcha, {
  siteKey: '6LfZZNYUAAAAACkUI85g2s6qVdZS0klOE43sQVWa'
})

Vue.component('apexchart', VueApexCharts)

export default new Vue({
  vuetify,
  router,
  store,
  async created() {
    await this.$recaptchaLoaded();

    this.$recaptchaInstance.hideBadge();
  },
  render: (h) => h(App),
}).$mount('#app')
