import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/WWSLoginLayout.vue"),
    children: [
      {
        path: "/",
        name: "wws-login-page",
        component: () => import("@/views/pages/LoginPage.vue"),
      },
    ],
  },
  {
    path: "/wws",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layouts/WWSLayout.vue"),
    children: [
      {
        path: "/wws/dashboard/overview",
        name: "Dashboard",
        meta: { request: "dashboard" },
        component: () => import("@/views/pages/dashboard/DashboardPage.vue"),
      },
      {
        path: "/wws/database/articles",
        name: "Artikel",
        meta: { request: "articles" },
        component: () => import("@/views/pages/database/articles/DatabaseArticlesPage.vue"),
      },
      {
        path: "/wws/database/articles/dataset/create",
        name: "Artikel erstellen",
        meta: { request: "articles" },
        component: () => import("@/views/pages/database/articles/dataset/CreateArticlePage.vue"),
      },
      {
        path: "/wws/database/articles/dataset/show/:id",
        name: "Artikel anzeigen",
        meta: { request: "articles" },
        component: () => import("@/views/pages/database/articles/dataset/ShowArticlePage.vue"),
      },
      {
        path: "/wws/database/articles/dataset/edit/:id",
        name: "Artikel bearbeiten",
        meta: { request: "articles" },
        component: () => import("@/views/pages/database/articles/dataset/EditArticlePage.vue"),
      },
      {
        path: "/wws/database/articles/dataset/copy/:id",
        name: "Artikel kopieren",
        meta: { request: "articles" },
        component: () => import("@/views/pages/database/articles/dataset/CopyArticlePage.vue"),
      },
      {
        path: "/wws/database/manufacturers",
        name: "Hersteller",
        meta: { request: "manufacturers" },
        component: () => import("@/views/pages/database/manufacturers/DatabaseManufacturersPage.vue"),
      },
      {
        path: "/wws/database/manufacturers/dataset/create",
        name: "Hersteller erstellen",
        meta: { request: "manufacturers" },
        component: () => import("@/views/pages/database/manufacturers/dataset/CreateManufacturerPage.vue"),
      },
      {
        path: "/wws/database/manufacturers/dataset/show/:id",
        name: "Hersteller anzeigen",
        meta: { request: "manufacturers" },
        component: () => import("@/views/pages/database/manufacturers/dataset/ShowManufacturerPage.vue"),
      },
      {
        path: "/wws/database/manufacturers/dataset/edit/:id",
        name: "Hersteller bearbeiten",
        meta: { request: "manufacturers" },
        component: () => import("@/views/pages/database/manufacturers/dataset/EditManufacturerPage.vue"),
      },
      {
        path: "/wws/appointments/requests",
        name: "Terminanfragen",
        meta: { request: "appointments/requests" },
        component: () => import("@/views/pages/appointments/requests/AppointmentrequestsPage.vue"),
      },
      {
        path: "/wws/appointments/requests/dataset/show/:id",
        name: "Terminanfragen",
        meta: { request: "appointments/requests" },
        component: () => import("@/views/pages/appointments/requests/dataset/ShowAppointmentrequestPage.vue"),
      },
      {
        path: "/wws/appointments/table",
        name: "Termine",
        meta: { request: "appointments/table" },
        component: () => import("@/views/pages/appointments/AppointmentsPage.vue"),
      },
      {
        path: "/wws/appointments/table/dataset/create/",
        name: "Termine",
        meta: { request: "appointments/table" },
        component: () => import("@/views/pages/appointments/dataset/CreateAppointmentPage.vue"),
      },
      {
        path: "/wws/appointments/table/dataset/show/:id",
        name: "Termine",
        meta: { request: "appointments/table" },
        component: () => import("@/views/pages/appointments/dataset/ShowAppointmentPage.vue"),
      },
      {
        path: "/wws/appointments/table/dataset/edit/:id",
        name: "Termine",
        meta: { request: "appointments/table" },
        component: () => import("@/views/pages/appointments/dataset/EditAppointmentPage.vue"),
      },
      {
        path: "/wws/appointments/calendar",
        name: "Kalender",
        component: () => import("@/views/pages/appointments/CalendarPage.vue"),
      },
      {
        path: "/wws/orders/proposal",
        name: "Vorgemerkte Bestellungen",
        meta: { request: "orders/proposal" },
        component: () => import("@/views/pages/orders/OrderProposalPage.vue"),
      },
      {
        path: "/wws/orders/ordered",
        name: "Bestellungen",
        meta: { request: "orders/ordered" },
        component: () => import("@/views/pages/orders/OrdersPage.vue"),
      },
      {
        path: "/wws/orders/ordered/dataset/show/:id",
        name: "Bestellung",
        meta: { request: "orders/ordered" },
        component: () => import("@/views/pages/orders/dataset/ShowOrderPage.vue"),
      },
      {
        path: "/wws/orders/ordered/dataset/create",
        name: "Vor-Order ersteller",
        meta: { request: "orders/ordered" },
        component: () => import("@/views/pages/orders/dataset/CreatePreorderPage.vue"),
      },
      {
        path: "/wws/settings/system",
        name: "Systemeinstellungen",
        component: () => import("@/views/pages/settings/SystemsettingsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata",
        name: "Stammdaten",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/MasterdataPage.vue"),
      },
      {
        path: "/wws/database/import",
        name: "Datenimport",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/ImportPage.vue"),
      },
      {
        path: "/wws/dashboard/todo",
        name: "To-Do Liste",
        meta: { request: "settings/todo" },
        component: () => import("@/views/pages/settings/TodoPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/coworkers",
        name: "Mitarbeiter",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/masterdata/CoworkersPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/departments",
        name: "Abteilungen",
        component: () => import("@/views/pages/settings/masterdata/DepartmentsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/groups",
        name: "Artikelgruppen",
        component: () => import("@/views/pages/settings/masterdata/GroupsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/subgroups",
        name: "Artikeluntergruppen",
        component: () => import("@/views/pages/settings/masterdata/SubgroupsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/colors",
        name: "Artikelfarben",
        component: () => import("@/views/pages/settings/masterdata/ColorsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/fabrics",
        name: "Artikelstoffe",
        component: () => import("@/views/pages/settings/masterdata/FabricsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/cuts",
        name: "Artikelschnitte",
        component: () => import("@/views/pages/settings/masterdata/CutsPage.vue"),
      },
      {
        path: "/wws/settings/masterdata/units",
        name: "Artikeleinheiten",
        component: () => import("@/views/pages/settings/masterdata/UnitsPage.vue"),
      },
      {
        path: "/wws/database/import/bridal",
        name: "Datenimport Brautmode",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/masterdata/BridalImportPage.vue"),
      },
      {
        path: "/wws/database/import/festive",
        name: "Datenimport Festmode",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/masterdata/FestiveImportPage.vue"),
      },
      {
        path: "/wws/database/import/men",
        name: "Datenimport Herrenmode",
        meta: { request: "settings/masterdata" },
        component: () => import("@/views/pages/settings/masterdata/MenImportPage.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/layouts/ErrorLayout.vue"),
    children: [
      {
        path: "",
        name: "error",
        component: () => import("@/views/pages/NotFoundPage.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      next();
    } else {
      next({
        name: "wws-login-page",
        params: {
          nextUrl: to.fullPath,
        },
      });
    }
  }
  next();
});

export default router;
